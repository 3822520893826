import React, { useCallback, useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  IconButton,
  Stack,
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getGroupMetadata } from "../../services/vehicle";
import { getVehiclesInGroups } from "../../services/vehicle";
import "../../styles/vehicleQueryBuilder.css";

import { QueryBuilder } from "react-querybuilder";
import { materialControlElements } from "@react-querybuilder/material";
import { useToast } from "../../components/toast";
import { createDynamicGroup, updateDynamicGroup } from "../../services/vehicle";
import { VEHICLE_GROUP_NAME_REGEX } from "../../services/Constants";

import {
  convertRulesToQueryString,
  FIELDS,
  COMBINATORS,
  OPERATORS
} from "./Utils";

const VehicleGroupDetails = ({
  groupObj,
  open,
  name,
  onClose,
  onSuccess,
  displayName,
}) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [groupData, setGroupData] = useState({});
  const [thingGroupName, setThingGroupName] = useState("");
  const [thingGroupDesc, setThingGroupDesc] = useState("");
  const [isStaticGroupEdit, setIsStaticGroupEdit] = useState(false);
  const [query, setQuery] = useState({
    combinator: "AND",
    rules: [
      {
        field: "thingName",
        value: "",
        operator: "beginsWith",
      },
      // {
      //   rules: [{ field: "firstName", value: "Stev", operator: "beginsWith" }],
      //   combinator: "and",
      // },
    ],
  });
  const [processedQueryString, setProcessedQueryString] = useState("");

  const getGroupDetails = useCallback(async (nameString) => {
    setLoading(true);
    // const { data = [] } = await getGroupMetadata(nameString);
    const data = [];
    if (data?.thingGroupName?.length) {
      setThingGroupName(data?.thingGroupName);
      setThingGroupDesc(
        data?.thingGroupProperties?.thingGroupDescription || ""
      );
      setIsStaticGroupEdit(!data?.queryString);
      setProcessedQueryString(data?.queryString || "");

      if (!!data?.ruleObject) {
        const queryRuleObject = JSON.parse(data?.ruleObject);
        setQuery(queryRuleObject);
      }
      setGroupData(data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  const handleClose = () => {
    if (onClose) onClose();
  };

  const showEditForm = () => {
    setShowEdit(true);
  };

  const handleSubmit = async (type) => {
    if (!thingGroupName || thingGroupName?.length < 2) {
      return;
    }
    setLoading(true);
    try {
      var params = {
        queryObject: query,
        thingGroupName: thingGroupName /* required */,
        queryString: processedQueryString /* required */,
        thingGroupProperties: {
          thingGroupDescription: thingGroupDesc,
        },
      };
      if (type === "create") {
        // const queryAttrs = convertRuleObjectToString(query);
        params.thingGroupProperties.attributePayload = {
          attributes: {
            // custom attributes

            groupType: "dynamic",
            // ...queryAttrs,
          },
        };
      } else if (type === "update") {
        // const queryAttrs = convertRuleObjectToString(query);
        params.thingGroupProperties.attributePayload = {
          attributes: {
            // custom attributes
            groupType: "dynamic",
            // ...queryAttrs,
          },
          merge: false, // Specifies whether the list of attributes provided in the AttributePayload is merged with the attributes stored in the registry, instead of overwriting them.
        };
      }
      const submitApi =
        type === "create" ? createDynamicGroup : updateDynamicGroup;
      const respose = await submitApi(params);

      if (respose) {
        const { statusText, data, statusCode = "", message = "" } = respose;
        if (statusCode && statusCode !== 200 && !!message) {
          addToast({
            type: "error",
            message: message,
            autoClose: 3000,
          });
        } else if (
          (statusText &&
            (statusText === "Created" || statusText === "Updated") &&
            data &&
            data?.thingGroupName) ||
          data?.version
        ) {
          if (type === "create") {
            if (onClose) onClose();
          } else {
            if (onSuccess) onSuccess();
            if (setShowEdit) setShowEdit(false);
            if (getGroupDetails) getGroupDetails(name);
          }
          addToast({
            type: "success",
            message: `Dynamic Group ${type === "update"
              ? `"${thingGroupName.split("_").pop()}" updated`
              : `"${data?.thingGroupName}" created`
              }  successfully.`,
            autoClose: 3000,
          });
        }
      } else {
        const { statusCode, message } = respose;
        if (statusCode && statusCode !== 200 && !!message) {
          addToast({
            type: "error",
            message: message,
            autoClose: 3000,
          });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const update = () => {
    handleSubmit("update");
  };

  const create = () => {
    handleSubmit("create");
  };

  useEffect(() => {
    if (!!name) {
      getGroupDetails(name);
    }

    return () => {
      setGroupData({});
      setShowEdit(false);
    };
  }, [name, getGroupDetails]);

  const groupDetailsProps = {
    groupData,
    groupObj,
  };

  const createEditProps = {
    thingGroupName,
    thingGroupDesc,
    processedQueryString,
    setThingGroupName,
    setThingGroupDesc,
    setProcessedQueryString,
    query,
    setQuery,
    isStaticGroupEdit,
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xl">
      <DialogTitle>
        {!!displayName
          ? showEdit
            ? `Edit Group "${displayName}"`
            : `Vehicle Group "${displayName}"`
          : "Create vehicle group query"}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {!!name ? (
          groupData?.thingGroupName || groupObj?.vehicleGroupName ? (
            <>
              {showEdit ? (
                <CreateEditForm name={displayName} {...createEditProps} />
              ) : (
                <GroupDetails {...groupDetailsProps} />
              )}
            </>
          ) : (
            <>
              <div style={{ width: "500px" }}>
                <CircularProgress size={25} />
              </div>
            </>
          )
        ) : (
          <CreateEditForm {...createEditProps} />
        )}
      </DialogContent>
      <DialogActions>
        {!!name ? (
          groupData?.thingGroupName ? (
            <>
              {groupData?.thingGroupName &&
                groupData?.thingGroupName.split("_")[0] !== "sys" &&
                groupData?.thingGroupName.split("_")[0] !== "dtg-sys" ? (
                <Button
                  variant="outlined"
                  disabled={loading}
                  onClick={() => {
                    if (showEdit) {
                      update();
                    } else {
                      showEditForm();
                    }
                  }}
                >
                  {showEdit ? "Update" : "Edit"}
                </Button>
              ) : null}

              <Button
                variant="filled"
                disabled={loading}
                onClick={() => {
                  if (showEdit) {
                    setShowEdit(false);
                  } else {
                    handleClose();
                  }
                }}
              >
                {showEdit ? "Show Details" : "Close"}
              </Button>
            </>
          ) : null
        ) : (
          <>
            <Button
              variant="outlined"
              disabled={!thingGroupName || thingGroupName.length < 2 || loading}
              onClick={() => handleSubmit("create")}
            >
              {loading ? <CircularProgress size={20} /> : "Submit"}
            </Button>

            <Button
              variant="filled"
              disabled={loading}
              onClick={() => {
                if (showEdit) {
                  setShowEdit(false);
                } else {
                  handleClose();
                }
              }}
            >
              {"Close"}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

const CreateEditForm = ({
  name,
  thingGroupName,
  setThingGroupName,
  thingGroupDesc,
  setThingGroupDesc,
  processedQueryString,
  setProcessedQueryString,
  query,
  setQuery,
  isStaticGroupEdit,
}) => {
  const [error, setError] = useState("");
  const processQueryString = useCallback(
    (queryObject) => {
      let finalQueryString = "";
      let result = "";
      const { combinator, rules } = queryObject;
      if (rules && rules.length) {
        const newRulesArray = rules.map((item, index) => {
          const rule = convertRulesToQueryString(item);
          if (combinator === "NOT") {
            result = index > 0 ? `${rule}` : `${combinator} ${rule}`;
          } else {
            result = index > 0 ? `${combinator} ${rule}` : `${rule}`;
          }
          if (item.combinator && item.rules?.length) {
            // TODO process nested group queries
          }
          return result;
        });
        finalQueryString = newRulesArray.join(" ");
      }
      setProcessedQueryString(finalQueryString);
      return finalQueryString;
    },
    [setProcessedQueryString]
  );

  useEffect(() => {
    if (query) {
      processQueryString(query);
    } else {
      // TODO
    }
  }, [query, processQueryString]);
  return (
    <>
      <Stack style={{ paddingTop: "12px" }} spacing={2}>
        <TextField
          disabled={!!name}
          required
          id="group_name"
          label="Group Name"
          style={{ minWidth: "460px" }}
          fullWidth
          value={name || thingGroupName}
          helperText={error} // error message
          error={!!error}
          onChange={({ target }) => {
            if (!target?.value.match(VEHICLE_GROUP_NAME_REGEX)) {
              setError("");
              setThingGroupName(target?.value); // only set when successful
            } else {
              setError(
                "White space and special characters are not allowed, except (-) hyphens"
              );
            }
          }}
        />
        <TextField
          id="outlined-multiline-static"
          label="Description"
          multiline
          rows={4}
          fullWidth
          value={thingGroupDesc}
          onChange={({ target }) => setThingGroupDesc(target?.value)}
        />
        {!isStaticGroupEdit && (
          <>
            <Card>
              <CardHeader
                title={<Typography>Build Query</Typography>}
              ></CardHeader>
              <CardContent>
                <QueryBuilder
                  fields={FIELDS}
                  combinators={COMBINATORS}
                  operators={OPERATORS}
                  query={query}
                  onQueryChange={(q) => setQuery(q)}
                  controlElements={{
                    ...materialControlElements,
                    addGroupAction: () => null,
                  }}
                />
              </CardContent>
            </Card>

            <Card>
              <CardHeader
                title={<Typography>Query preview</Typography>}
              ></CardHeader>
              <CardContent>
                <Box sx={{ typography: "body2" }}>
                  <Typography align="center">{processedQueryString}</Typography>
                </Box>
              </CardContent>
            </Card>
          </>
        )}
      </Stack>
    </>
  );
};

const GroupDetails = ({ groupObj, groupData }) => {
  const { displayName, thingGroupName, queryString, thingGroupMetadata } =
    groupData;
  return (
    <Stack spacing={2} style={{ paddingTop: "12px" }}>
      <TextField
        disabled
        id="group-name-disabled"
        label="Group Name"
        defaultValue={
          displayName || thingGroupName || groupObj?.vehicleGroupName
        }
      />

      <TextField
        disabled
        id="group-description-disabled"
        label="Group Description"
        multiline
        defaultValue={groupObj?.description || "No description"}
        style={{ minWidth: "500px" }}
      />

      {!!queryString ? (
        <TextField
          disabled
          id="group-query-string-disabled"
          label="Group Query String"
          multiline
          defaultValue={queryString}
        />
      ) : null}

      <VehiclesList
        vehicles={groupObj?.devices}
        thingGroupName={thingGroupName}
      />
    </Stack>
  );
};

const VehiclesList = (props) => {
  const { thingGroupName } = props;
  const [vehicles, setVehicles] = useState([...props.vehicles]);
  const [isExpanded, setIsExpanded] = useState(true);

  const getVehiclesForGroups = useCallback(
    async (list) => {
      const { data = [] } = await getVehiclesInGroups(list.toString());
      const vehiclesInGroups = data.map((i) => i.things);
      setVehicles(vehiclesInGroups.flat());
    },
    [setVehicles]
  );

  // useEffect(() => {
  //   if (thingGroupName && thingGroupName.length) {
  //     getVehiclesForGroups([thingGroupName]);
  //   } else {
  //     setVehicles([]);
  //   }
  // }, [thingGroupName, setVehicles, getVehiclesForGroups]);

  return (
    <>
      <Accordion
        expanded={isExpanded}
        onChange={() => setIsExpanded(!isExpanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{`Vehicles (${vehicles.length})`}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {vehicles?.length
              ? vehicles.map((i) => (
                <>
                  <ListItem
                    secondaryAction={
                      <Button
                        size="small"
                        component={RouterLink}
                        to={`/Vehicles/${i[0]?.serialNo}`}
                      >
                        Details
                      </Button>
                    }
                  >
                    {i[0]?.serialNo}
                  </ListItem>
                  <Divider variant="fullWidth" component="li" />
                </>
              ))
              : null}
          </List>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default VehicleGroupDetails;
