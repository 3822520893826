import React, { useState } from "react";
import { string, func, bool } from "prop-types";
import axios from "axios";
import { useQueryClient } from "react-query";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { Button } from "@mui/material";
import Constants from "../../services/Constants";

const UserDisableBtn = (props) => {
  const { userId, enabled } = props;
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [isEnabled, setIsEnabled] = useState(enabled);
  const enableDisableUsers = async ({ userId, isEnabled }) => {
    setLoading(true);
    let url = Constants.ENABLE_DISABLE_USER.replace(":userId", userId);
    isEnabled ? await axios.delete(url) : await axios.patch(url);
    setIsEnabled(!isEnabled);
    // Invalidate and refetch
    queryClient.invalidateQueries('getAllUsersData');
    queryClient.invalidateQueries('getAllUserGroups');
    setLoading(false);
  };
  if (loading)
    return <Button disabled startIcon={<HourglassTopIcon />}></Button>;
  return (
    <Button
      variant="text"
      color={isEnabled ? `primary` : `success`}
      onClick={() => enableDisableUsers({ isEnabled, userId })}
    >
      {isEnabled ? `Disable` : `Enable`}
    </Button>
  );
};

UserDisableBtn.defaultProps = {
  userId: "",
  enabled: false,
};
UserDisableBtn.propTypes = {
  userId: string,
  enabled: bool,
};

export default UserDisableBtn;
