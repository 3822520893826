import { Tooltip } from "@mui/material";
import React from "react";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";

const getColumnsData = () => {
  // Column props reference available at - ota-portal_v2\node_modules\.pnpm\@mui+x-data-grid@5.15.2_rrttaqilrsb3aifveu7wjrwxnm\node_modules\@mui\x-data-grid\models\colDef\gridColDef.d.ts
  return [
    {
      field: "serialNo",
      headerName: "Serial No",
      flex: 1,
      renderCell: (data) => (
        <Tooltip title={data?.value}>
          <p
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data?.value || "-"}
          </p>
        </Tooltip>
      ),
    },
    {
      field: "vehicleKey",
      headerName: "Vehicle Key",
      flex: 2,
      renderCell: (data) => (
        <Tooltip title={data?.value}>
          <p
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data?.value}
          </p>
        </Tooltip>
      ),
    },
    {
      field: "deploymentName",
      headerName: "Deployment Name",
      flex: 1,
      renderCell: (data) => (
        <Tooltip title={data?.value}>
          <p
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data?.value}
          </p>
        </Tooltip>
      ),
    },
    {
      field: "imageName",
      headerName: "Image Name",
      flex: 1,
      renderCell: (data) => {
        return <Tooltip title={data?.value}>
          <p
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data?.value}
          </p>
        </Tooltip>
      },
    },
    {
      field: "vehicleDeploymentStatus",
      headerName: "Status",
      flex: 0.75,
      renderCell: (data) => {
        return <Tooltip title={data?.value}>
          <p
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data?.value}
          </p>
        </Tooltip>
      },
    },
    {
      field: "date",
      headerName: "Date",
      type: "dateTime",
      flex: 0.75,
      renderCell: (data) => (
        <DateAndTimeGridCell value={data?.value} />
      ),
    }
  ];
};

export { getColumnsData };
