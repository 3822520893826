import React, { useState, Fragment } from "react";
import {
  Typography,
  Button,
  Stack,
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  ListItemText,
} from "@mui/material";
import { TextField, PhoneNumberField } from "@aws-amplify/ui-react";
import SaveIcon from '@mui/icons-material/Save';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { createNewUser, getUserDetails } from "../../services/Users";
import { useToast } from "../../components/toast";
import { GROUPS } from "../../services/Constants";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getAllAvailableOrgs } from "../../services/Users";

const CreateUserForm = ({ onUserCreated }) => {
  const queryClient = useQueryClient();
  const { data: orgs } = useQuery("getAllAvailableOrgs", getAllAvailableOrgs, {
    refetchOnWindowFocus: true,
  });
  const admin = useQuery("getUserDetails", getUserDetails, {
    refetchOnWindowFocus: false
  });
  const { addToast } = useToast();
  let defaultGroup = null;
  const GroupsArray = Object.entries(GROUPS).map((i) => {
    const newGroup = { name: i[1], value: i[0] };
    if (i[1] === "Guest") {
      newGroup.selected = true;
      newGroup.disabled = true;
      defaultGroup = newGroup;
    }
    return newGroup;
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("+1");
  const [selectedGroups, setSelectedGroups] = useState([defaultGroup.value]);
  const [selectedOrgs, setSelectedOrgs] = useState([]);
  const [selectedOrgsIds, setSelectedOrgsIds] = useState([]);

  const handleOrgChange = (event) => {
    const {
      target: { value },
    } = event;
    const items = typeof value === "string" ? value.split(",") : value; // On autofill we get a stringified value.
    setSelectedOrgs(items);
    const itemsIds = orgs
      ?.filter((i) => items?.indexOf(i?.customerName) > -1)
      ?.map((i) => i?.customerId);
    setSelectedOrgsIds(itemsIds);
  };

  const handleChange = (event) => {
    const { id } = event?.target;
    const foundItem = selectedGroups.filter((i) => i === id);
    if (foundItem && foundItem.length) {
      const newGroup = selectedGroups.filter((i) => i !== id);
      setSelectedGroups([...newGroup]);
    } else {
      const newGroup = selectedGroups.filter((i) => i !== id);
      setSelectedGroups([...newGroup, id]);
    }
  };

  const clearForm = () => {
    setName("");
    setPhone("");
    setEmail("");
  };

  const { mutate: handleCancelVehicle, isLoading: submitIsLoading } =
    useMutation(createNewUser, {
      onSuccess: (data) => {
        const { User, statusCode = "", message = "" } = data;
        if (statusCode && statusCode !== 200 && !!message) {
          addToast({
            type: "error",
            message: message,
            autoClose: 3000,
          });
        } else if (User && !!User.Username) {
          clearForm();
          addToast({
            type: "success",
            message: `User created successfully. temporary password is sent to the user's email.`,
            autoClose: 3000,
          });
          onUserCreated();
        }
      },
      onError: (err) => {
        const { statusCode, message } = err;
        if (statusCode && statusCode !== 200 && !!message) {
          addToast({
            type: "error",
            message: message,
            autoClose: 3000,
          });
        }
      },
      onSettled: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries('getAllUsersData');
      }
    });

  const createUser = async () => {
    var params = {
      Username: email /* required */,
      DesiredDeliveryMediums: ["EMAIL"],
      ForceAliasCreation: false,
      UserAttributes: [
        {
          Name: "name" /* required */,
          Value: name,
        },
        {
          Name: "email" /* required */,
          Value: email,
        },
        {
          Name: "phone_number" /* required */,
          Value: countryCode + phone,
        },
        {
          Name: "custom:orgs" /* required */,
          Value: selectedOrgsIds?.join(","),
        },
      ],
    };

    handleCancelVehicle({
      admin,
      params,
      userGroups: selectedGroups,
    });
  };

  const validateForm = () => {
    let invalid = !name || !email || !phone;
    const regex = /^\s+$/;
    const nameValidator = /[^a-zA-Z0-9\s-_.]/;
    const emailValidator = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    if (regex.test(name)) invalid = true
    if (nameValidator.test(name)) invalid = true
    if (!emailValidator.test(email)) invalid = true
    if (regex.test(phone)) invalid = true
    if (phone.match(/[a-z]/i)) invalid = true
    if (selectedOrgs.length <= 0) invalid = true
    return invalid;
  }

  return (
    <Fragment>
      <Box>
        <Typography
          color="text.primary"
          sx={{ mt: 5 }}
          style={{ marginBottom: "18px" }}
        >
          Add Users and provide the right level of access to the roles.
        </Typography>
        <Stack spacing={2}>
          <TextField
            hasError={false}
            inputMode="text"
            isRequired={true}
            label="Name"
            placeholder=""
            type="text"
            wrap="nowrap"
            onChange={(e) => setName(e.currentTarget.value)}
            value={name}
          />
          <TextField
            label={"Email"}
            type="email"
            isRequired={true}
            onChange={(e) => setEmail(e.currentTarget.value.replaceAll(/\s+/g, ''))}
            value={email}
          />
          <PhoneNumberField
            defaultCountryCode="+1"
            label="Phone Number"
            name="phone_number"
            isRequired={true}
            onCountryCodeChange={(e) => setCountryCode(e.target.value)}
            onChange={(e) => setPhone(e.currentTarget.value)}
            value={phone}
          />

          <FormControl sx={{ m: 1, width: "100%" }}>
            <InputLabel id="demo-multiple-checkbox-label">
              Organization
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={selectedOrgs}
              onChange={handleOrgChange}
              input={<OutlinedInput label="Organization" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {orgs?.map((name) => (
                <MenuItem key={name.customerId} value={name.customerName}>
                  <Checkbox
                    checked={selectedOrgs.indexOf(name?.customerName) > -1}
                  />
                  <ListItemText primary={name?.customerName} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
            <FormLabel component="legend">{`Assign to Groups`}</FormLabel>
            <FormGroup>
              {GroupsArray.map((i) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      id={i.value}
                      checked={i.selected}
                      onChange={(e) => handleChange(e)}
                      value={i}
                      name={i.name}
                      disabled={i.disabled}
                    />
                  }
                  label={i.name}
                />
              ))}
            </FormGroup>
          </FormControl>

          <Typography
            color="text.secondary"
            sx={{ mt: 5 }}
            style={{ marginBottom: "18px" }}
          >
            A temporary password will be generated and sent to the user's email
            on successfull creation of the user account.
          </Typography>

          <Button
            size="large"
            variant="contained"
            color="primary"
            startIcon={submitIsLoading ? <HourglassBottomIcon /> : <SaveIcon />}
            onClick={() => createUser()}
            disabled={validateForm() || submitIsLoading}
          >
            {submitIsLoading ? 'Loading...' : 'Submit'}
          </Button>
        </Stack>
      </Box>
    </Fragment>
  );
};

export default CreateUserForm;
