import React, { Fragment, useContext } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  Skeleton,
  IconButton,
  Tooltip,
  Modal,
  Box,
} from "@mui/material";
import styled from "styled-components";
import { useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { grey } from "@mui/material/colors";
import { useTheme } from '@mui/material/styles';
import ExtendedAnalytics from './ExtendedAnalytics';
import { getStatsForDashboard } from "../../services/Dashboard";
import { WizardContext, ACTIONS } from "../wizard/Context";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";
import { MODULES } from "../../services/Constants";
import useModule from "../../hooks/useModule";
import dualbg from '../../images/dual-bg.svg';
import DottedTile from "../common/DottedTile";

const DashboardStats = () => {
  const [type, setType] = React.useState("vehicles");
  const [open, setOpen] = React.useState(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    bgcolor: 'background.paper',
    p: 4,
  };

  const theme = useTheme();
  const AnalyticsWrapper = styled.div`
    width: 100%;
    position: absolute;
    text-align: end;
    button {
        color: ${theme.custom.dualTheme ? theme.palette.primary[600] : grey[500]}
    }
    `;

  const { validateModuleWithMessage } = useModule();
  const { dispatch } = useContext(WizardContext);
  const { data, isLoading } = useQuery(
    "getStatsForDashboard",
    getStatsForDashboard,
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Grid item xs={12} sm={6} md={2}>
        <CardActionArea LinkComponent={RouterLink} to={`/vehicles`}>
          <Card>
            {!isLoading && theme.custom.dualTheme &&
              <AnalyticsWrapper>
                <Tooltip title="Show Analytics">
                  <IconButton aria-label="analytics" alt
                    onClick={(e) => {
                      e.preventDefault();
                      setType("vehicles");
                      handleOpen();
                    }}>
                    <AnalyticsIcon />
                  </IconButton>
                </Tooltip>
              </AnalyticsWrapper>}
            <CardContent
              {...(theme.custom.dualTheme && { sx: { backgroundImage: `url('${dualbg}')`, backgroundSize: 'cover' } })}
            >
              {isLoading ? (
                <Skeleton animation="wave" />
              ) : (
                <Typography color="text.secondary">
                  Vehicles Provisioned
                </Typography>
              )}
              {isLoading ? (
                <Skeleton animation="wave" height={90} width={60} />
              ) : (
                <Typography
                  color={theme.palette.primary['A700']}
                  sx={{ fontSize: 60, fontWeight: 300 }}
                >
                  {data?.vehicles ? data.vehicles : "-"}
                </Typography>
              )}
            </CardContent>
          </Card>
        </CardActionArea>
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <CardActionArea LinkComponent={RouterLink} to={`/images`}>
          <Card>
            {!isLoading && theme.custom.dualTheme &&
              <AnalyticsWrapper>
                <Tooltip title="Show Analytics">
                  <IconButton aria-label="analytics" alt
                    onClick={(e) => {
                      e.preventDefault();
                      setType("images");
                      handleOpen();
                    }}>
                    <AnalyticsIcon />
                  </IconButton>
                </Tooltip>
              </AnalyticsWrapper>}
            <CardContent
              {...(theme.custom.dualTheme && { sx: { backgroundImage: `url('${dualbg}')`, backgroundSize: 'cover' } })}
            >
              {isLoading ? (
                <Skeleton animation="wave" />
              ) : (
                <Typography color="text.secondary">Images uploaded</Typography>
              )}
              {isLoading ? (
                <Skeleton animation="wave" height={90} width={60} />
              ) : (
                <Typography
                  color={theme.palette.primary['A700']}
                  sx={{ fontSize: 60, fontWeight: 300 }}
                >
                  {data?.images ? data.images : "-"}
                </Typography>
              )}
            </CardContent>
          </Card>
        </CardActionArea>
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <CardActionArea LinkComponent={RouterLink} to={`/releases`}>
          <Card>
            {!isLoading && theme.custom.dualTheme &&
              <AnalyticsWrapper>
                <Tooltip title="Show Analytics">
                  <IconButton aria-label="analytics" alt
                    onClick={(e) => {
                      e.preventDefault();
                      setType("releases");
                      handleOpen();
                    }}>
                    <AnalyticsIcon />
                  </IconButton>
                </Tooltip>
              </AnalyticsWrapper>}
            <CardContent
              {...(theme.custom.dualTheme && { sx: { backgroundImage: `url('${dualbg}')`, backgroundSize: 'cover' } })}
            >
              {isLoading ? (
                <Skeleton animation="wave" />
              ) : (
                <Typography color="text.secondary">Releases</Typography>
              )}
              {isLoading ? (
                <Skeleton animation="wave" height={90} width={60} />
              ) : (
                <Typography
                  color={theme.palette.primary['A700']}
                  sx={{ fontSize: 60, fontWeight: 300 }}
                >
                  {data?.releases ? data.releases : "-"}
                </Typography>
              )}
            </CardContent>
          </Card>
        </CardActionArea>
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <CardActionArea LinkComponent={RouterLink} to={`/deployments`}>
          <Card>
            {!isLoading && theme.custom.dualTheme &&
              <AnalyticsWrapper>
                <Tooltip title="Show Analytics">
                  <IconButton aria-label="analytics" alt
                    onClick={(e) => {
                      e.preventDefault();
                      setType("deployments");
                      handleOpen();
                    }}>
                    <AnalyticsIcon />
                  </IconButton>
                </Tooltip>
              </AnalyticsWrapper>}
            <CardContent
              {...(theme.custom.dualTheme && { sx: { backgroundImage: `url('${dualbg}')`, backgroundSize: 'cover' } })}
            >
              {isLoading ? (
                <Skeleton animation="wave" />
              ) : (
                <Typography color="text.secondary">Deployments</Typography>
              )}
              {isLoading ? (
                <Skeleton animation="wave" height={90} width={60} />
              ) : (
                <Typography
                  color={theme.palette.primary['A700']}
                  sx={{ fontSize: 60, fontWeight: 300 }}
                >
                  {data?.deployments ? data.deployments : "-"}
                </Typography>
              )}
            </CardContent>
          </Card>
        </CardActionArea>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        width="1200px"
      >
        <Box sx={style}>
          <div style={{
            minWidth: 0,
            height: 400,
            marginBottom: '5%'
          }}>
            <Typography variant="h6" gutterBottom style={{ textTransform: 'capitalize', textAlign: 'center' }}>
              {type} Analytics
            </Typography>
            <ExtendedAnalytics type={type} color={theme.palette.primary[600]} />
          </div>
        </Box>
      </Modal>
      <Check I={CAN.CREATE} a={SUBJECTS.DEPLOYMENT}>
        <Grid item xs={12} sm={6} md={2}>
          <CardActionArea
            onClick={() => {
              if (validateModuleWithMessage(MODULES.OTA)) {
                dispatch({
                  type: ACTIONS.TOGGLE_WIZARD,
                  payload: {
                    showWizard: true,
                  },
                })
              }
            }
            }
          >
            <DottedTile title={"CREATE A DEPLOYMENT"}>
              <RocketLaunchIcon
                sx={{ fontSize: "5.4rem", color: grey[500] }}
              />
            </DottedTile>
          </CardActionArea>
        </Grid>
      </Check>
    </Fragment>
  );
};

DashboardStats.defaultProps = {};

DashboardStats.propTypes = {};

export default DashboardStats;
