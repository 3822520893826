import React from "react";
import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import {
    Grid,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@mui/material";
import { useQuery } from "react-query";
import CircularProgress from "@mui/material/CircularProgress";
import { getJobExecutionsforConfig } from "../../services/configuration";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";

const VehicleConfigurationList = (data = {}) => {
    let param = useParams();
    const { vehicleId, configId } = param;
    const { vehicles = [] } = data;

    // jobExecutions Queries
    let {
        data: { data: { executionSummaries = [] } = {} } = {},
        isLoading: summaryLoading,
    } = useQuery(["jobExecutions", configId], getJobExecutionsforConfig, {
        enabled: !!configId,
        refetchOnWindowFocus: false,
    });

    vehicles.map((vehicle) => {
        const jobExecution = executionSummaries.find(
            (execution) =>
                execution &&
                execution.thingArn &&
                execution.thingArn.split("/").pop() === vehicle.serialNo
        );
        if (jobExecution && jobExecution.jobExecutionSummary) {
            vehicle["jobExecutionSummary"] = jobExecution.jobExecutionSummary;
        } else {
            vehicle["jobExecutionSummary"] = {};
        }
        return vehicle;
    });

    if (summaryLoading) {
        return <CircularProgress />;
    }

    return (
        <Grid item xs={12}>
            <TableContainer
                sx={{
                    maxHeight: 800,
                    overflow: "auto",
                }}
            >
                <Table
                    stickyHeader
                    sx={{ minWidth: 650, border: "1px solid #e2e2e2" }}
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow>
                            {vehicleId && <TableCell>Configuration Id</TableCell>}
                            {!vehicleId && (
                                <>
                                    <TableCell>Serial No</TableCell>
                                    <TableCell>Vehicle Key</TableCell>
                                </>
                            )}
                            <TableCell>Status</TableCell>
                            <TableCell>Queued At</TableCell>
                            <TableCell>Started At</TableCell>
                            <TableCell>Last Updated At</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {vehicles.map((vehicle) => (
                            <TableRow
                                key={vehicle.serialNo}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                                {vehicleId && (
                                    <TableCell component="th" scope="row">
                                        {configId}
                                    </TableCell>
                                )}
                                {!vehicleId && (
                                    <>
                                        <TableCell>
                                            <Tooltip title={vehicle.serialNo}>
                                                <Button
                                                    size="medium"
                                                    sx={{
                                                        justifyContent: "flex-start",
                                                        textTransform: "none",
                                                    }}
                                                    component={RouterLink}
                                                    to={`/Vehicles/${vehicle.serialNo}/config`}
                                                >
                                                    {vehicle.serialNo}
                                                </Button>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>{vehicle.vehicleKey}</TableCell>
                                    </>
                                )}
                                <TableCell>
                                    {vehicle.jobExecutionSummary.status
                                        ? vehicle.jobExecutionSummary.status
                                        : "-"}
                                </TableCell>
                                <TableCell>
                                    {vehicle.jobExecutionSummary.queuedAt ? (
                                        <DateAndTimeGridCell
                                            value={vehicle.jobExecutionSummary.queuedAt}
                                        />
                                    ) : (
                                        "-"
                                    )}
                                </TableCell>
                                <TableCell>
                                    {vehicle.jobExecutionSummary.startedAt ? (
                                        <DateAndTimeGridCell
                                            value={vehicle.jobExecutionSummary.startedAt}
                                        />
                                    ) : (
                                        "-"
                                    )}
                                </TableCell>
                                <TableCell>
                                    {vehicle.jobExecutionSummary.lastUpdatedAt ? (
                                        <DateAndTimeGridCell
                                            value={vehicle.jobExecutionSummary.lastUpdatedAt}
                                        />
                                    ) : (
                                        "-"
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Button size="small" component={RouterLink} to={`/Vehicles/${vehicle.serialNo}/config`}>
                                        Details
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};

export default VehicleConfigurationList;
