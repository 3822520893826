import { Auth } from "aws-amplify";
import axios from "axios";
import Constants, { TOKEN_EXP_BUFFER_IN_MIN } from "./Constants";

const getUserName = async () => {
  let user = await Auth.currentAuthenticatedUser();
  return user.attributes.name;
};

const getUserDetails = async () => {
  const url = Constants.GET_USER_DATA_BY_COGNITO_ID;
  const { data = [] } = await axios.get(url);
  return data;
};

const getCustomerDetails = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const url = Constants.GET_CUSTOMER_DETAILS.replace(":userId", id);
  const { data = [] } = await axios.get(url);
  return data;
};

const getCurrentUserCustomerDetails = async () => {
  const url = Constants.GET_CURRENT_USER_CUSTOMER_DETAILS;
  const { data = [] } = await axios.get(url);
  return data;
};

const getUser = async () => {
  return await Auth.currentAuthenticatedUser();
};

const getIDToken = () => {
  return Auth.user.signInUserSession.idToken.jwtToken;
};

const getUserGroups = async () => {
  const attributes = await Auth.currentSession();
  const { accessToken: { payload = {} } = {} } = attributes;
  return payload["cognito:groups"] || [];
};

const isIdTokenExpired = () => {
  var idTokenExpire = Auth.user.signInUserSession.idToken.getExpiration();
  const currentTime = Math.trunc(new Date().getTime() / 1000);
  const expBuffer = TOKEN_EXP_BUFFER_IN_MIN * 60;
  const expThreshold = idTokenExpire - expBuffer;
  return currentTime > expThreshold;
};

const refreshIdToken = async () => {
  var refreshToken = await Auth.user.signInUserSession.getRefreshToken();
  var currentAuthUser = await Auth.currentAuthenticatedUser();
  return new Promise((resolve, reject) => {
    currentAuthUser.refreshSession(refreshToken, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data.getIdToken().getJwtToken());
      }
    });
  });
};

const getUserById = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const url = Constants.GET_USER_DATA + id;
  const { data = [] } = await axios.get(url);
  return data;
};

const getUserActivityByUserId = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const url = Constants.GET_USER_ACTIVITY + id;
  const { data = [] } = await axios.get(url);
  return data;
};

const getCurrentUserActivity = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const url = Constants.GET_CURRENT_USER_ACTIVITY;
  const { data = [] } = await axios.get(url);
  return data;
};

const getGroupsForUserId = async ({ queryKey }) => {
  const [_, id] = queryKey;
  let url = Constants.GET_USER_GROUP.replace(":userId", id);
  const { data = [] } = await axios.get(url);
  return data;
};

const createNewUser = async (payload) => {
  const url = Constants.CREATE_NEW_USER;
  const { data } = await axios.post(url, payload);
  return data;
};

const getUserPools = async () => {
  const url = Constants.GET_USER_POOLS;
  const { data } = await axios.get(url);
  const { UserPools = [] } = data;
  return UserPools;
};

const getAllUsersData = async () => {
  const url = Constants.LIST_USERS_ALL;
  const { data = {} } = await axios.get(url);
  const { users = [] } = data;
  return users;
};

const getAllUserGroups = async () => {
  const url = Constants.LIST_GROUPS;
  const { data = {} } = await axios.get(url);
  return data;
};

const getAllAvailableOrgs = async () => {
  const url = Constants.GET_ALL_ORGS;
  const { data = {} } = await axios.get(url);
  return data;
};

const getUserOrgs = async () => {
  const url = Constants.GET_ORGS_LIST_FOR_USER;
  const { data = {} } = await axios.get(url);
  return data;
};

const updateUserOrgs = async ({ userId, payload }) => {
  const url = Constants.USER_ORGS.replace(":userId", userId);
  const { data } = await axios.patch(url, payload);
  return data;
};

const getGroupsListForUser = async (id) => {
  let url = Constants.GET_USER_GROUP.replace(":userId", id);
  const { data = [] } = await axios.get(url);
  return data;
};

export {
  getUserName,
  getUser,
  getIDToken,
  isIdTokenExpired,
  refreshIdToken,
  getUserGroups,
  getUserById,
  getUserDetails,
  getUserActivityByUserId,
  getCurrentUserActivity,
  getGroupsForUserId,
  createNewUser,
  getUserPools,
  getAllUsersData,
  getAllUserGroups,
  getAllAvailableOrgs,
  updateUserOrgs,
  getUserOrgs,
  getGroupsListForUser,
  getCustomerDetails,
  getCurrentUserCustomerDetails,
};
